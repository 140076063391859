import React from 'react';
import styled from 'styled-components';
import imghistorie from '../assets/images/img-historie.jpg';
import renovatieimg from '../assets/images/renovatie_img.jpg';
import Layout from '../components/Layout';

const Bodystyles = styled.div`
  .grid-container {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 550px);
    grid-template-rows: 1fr;
    grid-gap: 0px 50px;
  }

  .wrapper {
    background: #ffffff;
  }

  .grid-container:nth-of-type(1) {
    background-color: #e8f0fb;
    height: 685px;
    align-items: center;
  }

  .grid-container:nth-of-type(2) {
    height: 685px;
    align-items: center;
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    font-weight: 700;
    font-size: 70px;
    color: #14284e;
    text-align: center;
    margin-top: 150px;
  }

  h2,
  h3 {
    font-size: 35px;
    line-height: 35px;
    font-weight: 700;
    color: #14284e;
  }

  .tekst {
    line-height: 35px;
    color: #14284e;
    font-size: 16px;
  }

  img {
    width: 550px;
    height: auto;
  }

  .historie,
  .historie2 {
    width: 460px;
  }

  .flexbox-2ndtekst {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
  }

  @media (max-width: 1200px) {
    .grid-container {
      grid-template-columns: repeat(2, 450px);
    }
    img {
      width: 450px;
      height: auto;
    }
  }

  @media (max-width: 997px) {
    .renovatie {
      margin-top: 170px;
    }

    .grid-container {
      grid-template-rows: repeat(2, 450px);
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0px;
      height: 1000px !important;
      justify-items: center;
      padding: 50px 0px;
    }

    .historie:nth-of-type(1) {
      margin-top: 100px !important;
    }

    .grid-container:nth-of-type(2) {
      padding-bottom: 0;
      height: 900px;
    }
  }

  @media (max-width: 500px) {
    .renovatie {
      margin-top: -30px;
    }

    .second {
      margin-top: 30px !important;
    }

    h1 {
      font-size: 50px !important;
      margin-bottom: 50px;
    }
    h2 {
      font-size: 24px;
      text-align: center;
    }
    p {
      font-size: 14px;
      margin: auto;
    }
    .grid-container {
      padding-bottom: 40px !important;
      grid-gap: 50px 0px;
      padding: 0;
      &:nth-of-type(1) {
        height: 1100px !important;
      }
      &:nth-of-type(2) {
        padding-top: 40px;
        height: 810px !important;
      }
      grid-template-rows: repeat(2, 1fr);
      img {
        width: 300px;
        height: 250px;
        &:nth-of-type(2) {
          margin-top: 15px !important;
        }
      }
      .grid-container > .renovatie {
        margin-bottom: 50px !important;
      }
    }
    .historie2 {
      margin-bottom: 100px;
      width: 300px;
      margin: auto;
    }
    .tekst:first-child {
      margin-bottom: 50px !important;
    }
    .historie {
      &nth-of-type(1) {
        margin-top: 0 !important;
      }
      width: 300px;
      margin-top: 20px !important;
    }
  }
`;

const HetHuis = () => (
  <Layout>
    <Bodystyles>
      <div className="main">
        <h1>Het Huis</h1>
        <div className="grid-container">
          <div className="historie">
            <h2>Een stukje historie over Adelhof</h2>
            <p className="tekst">
              In 1979 kochten onze ouders samen met een broer van onze vader,
              dit prachtige huis. Het huis werd verdeeld in twee op zichzelf
              staande appartementen. Onze ouders kozen de beneden verdieping.
              Vele jaren hebben ze mogen genieten van het appartement in deze
              mooie omgeving. Nu ze niet meer in ons midden zijn, hebben wij
              als kinderen het voorrecht om deze geschiedenis voort te zetten.
              We waren vast besloten dat we dit mooie huis wilde behouden en
              zijn er dankbaar voor dat we ervan mogen genieten op een manier
              zoals zij ook deden.
            </p>
          </div>
          <img
            draggable="false"
            alt=""
            className="renovatie"
            src={imghistorie}
          />
        </div>
        <div className="grid-container">
          <img
            draggable="false"
            className="second"
            alt=""
            src={renovatieimg}
          />
          <div className="flexbox-2ndtekst">
            <div className="historie2">
              <h2>Renovatie</h2>
              <p className="tekst" id="renovatietekst">
                In 2006 heeft Adelhof een renovatie ondergaan en het resultaat
                mag er zijn! Het appartement heeft een gezellige woonkamer,
                een ruim ingerichte keuken met o.a. een afwasmachine en een
                oven/magnetron, 3 slaapkamers en 2 luxe badkamers. Om het
                comfort optimaal te laten zijn is er ruimte voor een verblijf
                van 8 gasten met een eigen entree. Met dit alles kan Adelhof
                onze gasten een ultiem vakantiegevoel bieden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Bodystyles>
  </Layout>
);

export default HetHuis;
